// Utility functions for localStorage management with Promises

const getUserToken = () => {
  try {
    return localStorage.getItem('authToken') || null;
  } catch (error) {
    console.error('Error retrieving user token from local storage:', error);
    return null;
  }
};

const getStore = (key) => {
  return new Promise((resolve) => {
    try {
      const value = localStorage.getItem(key) || null;
      resolve(value); // Always resolve, even if value is null
    } catch (error) {
      console.error('Error retrieving from local storage:', error);
      resolve(null); // Resolve with null to prevent promise rejection
    }
  });
};

const setStore = (key, value) => {
  return new Promise((resolve) => {
    try {
      localStorage.setItem(key, value);
      resolve(true); // Resolve with true on success
    } catch (error) {
      console.error('Error saving to local storage:', error);
      resolve(false); // Resolve with false instead of rejecting
    }
  });
};


const removeStore = (key) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.removeItem(key);
      resolve(true); // Resolve with success
    } catch (error) {
      console.error('Error removing from local storage:', error);
      reject(false); // Reject with failure
    }
  });
};

const clearStore = () => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.clear();
      resolve(true); // Resolve with success
    } catch (error) {
      console.error('Error clearing local storage:', error);
      reject(false); // Reject with failure
    }
  });
};

// Export functions
export { getUserToken, setStore, getStore, removeStore, clearStore };
